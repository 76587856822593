<template>
  <app-modal-invoice-pdf
    :show="showInvoicePdf"
    @close="showInvoicePdf = false"
    :invoice="selectedInvoice"
    @update:invoice="selectedInvoice = $event"
  />
  <app-modal-invoice-view
    :show="showInvoiceView"
    @close="showInvoiceView = false"
    @refresh="refresh"
  />
  <app-modal
    :show="showDeleteConfirmation"
    @close="showDeleteConfirmation = false"
  >
    <template v-slot:title>{{ $t("invoice.action.delete_invoice") }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("invoice.action.delete_invoice_desc") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="deleteInvoice"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          sm:ml-3 sm:w-auto
          bg-error
          hover:bg-error
          text-white
          outline-none
        "
      >
        {{ $t("general.delete") }}
      </app-button>
    </template>
  </app-modal>
  <div class="container mx-auto">
    <div class="px-4 border-b p-2 mt-5">
      <div class="
          px-5
          py-2
          flex
          m-auto
          lg:px-0
          container
          items-center
          justify-between
        ">
        <div>
          <span class="text-xl">{{ $t("invoice.invoice_title") }}</span>
        </div>
        <div class="flex flex-row space-x-2">
          <app-button width="w-1/8" :showf70Icon="false" @click="goToCreateNewInvoice" :loading="loading">
            <template v-slot:icon>
              <app-icon-outline name="PlusIcon" class="h-5 w-5 text-white mr-2" />
            </template>
            {{ $t("invoice.create_new_invoice") }}
          </app-button>
        </div>
      </div>
    </div>
    <app-table
      :apiResponse="apiPaginationResponse.data"
      :loading="loading"
      @params-changed="paramsChanged"
      @empty-button-clicked="goToCreateNewInvoice"
      @on-item-click="displayInvoiceView"
      :emptyTitle="$t('invoice.no_invoice_title')"
      :emptyDescription="$t('invoice.no_invoice_description')"
      :emptyButton="$t('invoice.create_new_invoice')"
      :tabs="tabs"
      :filters="[
        {
          key: 'filter[customer_name]',
          type: 'text',
          placeholder: $t('customer.customer_name'),
          value: '',
        },
        {
          key: 'filter[amount]',
          type: 'number',
          placeholder: $t('invoice.table_header.due_amount'),
          value: '',
        },
        {
          key: 'filter[customer_email]',
          type: 'text',
          placeholder: $t('customer.customer_email'),
          value: '',
        },
        {
          key: 'filter[status]',
          type: 'select',
          placeholder: $t('invoice.table_header.status'),
          value: {},
          items: [
            {
              id: 'created',
              name: 'Created',
            },
            {
              id: 'open',
              name: 'Open',
            },
            {
              id: 'void',
              name: 'Void',
            },
            {
              id: 'overdue',
              name: 'Overdue',
            },
          ],
        },
      ]">
      <template v-slot:header>
        <th class="font-bold">{{ $t("invoice.table_header.status") }}</th>
        <th class="font-bold">{{ $t("invoice.table_header.no") }}</th>
        <th class="font-bold">
          {{ $t("invoice.table_header.customer_name") }}
        </th>
        <th class="font-bold">
          {{ $t("invoice.table_header.customer_email") }}
        </th>
        <th class="font-bold">{{ $t("invoice.table_header.due_amount") }}</th>
        <th class="font-bold">{{ $t("invoice.table_header.due_date") }}</th>
        <th class="font-bold">{{ $t("general.actions") }}</th>
      </template>
      <template v-slot:body="data">
        <td>
          <span
            class="px-3 py-2 rounded-md"
            :class="{
              'bg-gray-200 text-gray-600':
                data.model.status === invoiceStatus.CREATED,
              'bg-blue-100 text-blue-900':
                data.model.status === invoiceStatus.OPEN,
              'bg-red-400 text-white':
                data.model.status === invoiceStatus.OVERDUE,
              'bg-green-400 text-white':
                data.model.status === invoiceStatus.PAID,
              'bg-blue-300 text-blue-600':
                data.model.status === invoiceStatus.VOID,
            }"
          >
            {{ $t(`invoice.detail.status.${data.model.status}_short`) }}
          </span>
        </td>
        <td>{{ data.model.invoice_no }}</td>
        <td>{{ data.model.customer.name }}</td>
        <td>{{ data.model.customer.email }}</td>
        <td>
          <p class="font-medium">
            {{
                $formats.currency(data.model.currency, data.model.total_amount ?? 0.0)
            }}
          </p>
        </td>
        <td>
          {{ $moment(data.model.created_at).format("DD MMM YYYY ") }}
        </td>
        <td>
          <div class="flex space-x-2 text-gray-400">
            <app-button-outline
              v-if="data.model.status === invoiceStatus.CREATED"
              class="py-1"
              @click.stop="displayEditInvoiceForm(data.model)"
            >
              {{ $t("invoice.action.edit_invoice") }}
            </app-button-outline>
            <app-button-outline
              v-else
              class="py-1"
              @click.stop="displayInvoicePdf(data.model)"
            >
              {{ $t("invoice.action.view_invoice") }}
            </app-button-outline>
            <app-button-dropdown @click.stop>
              <div class="py-2">
                <app-icon
                  name="DotsVerticalIcon"
                  :class="['h-5 w-5 text-gray-500 transition transform']"
                >
                </app-icon>
              </div>
              <template #menu-items>
                <app-button-dropdown-item
                  v-for="item in getActionItems(data.model.status)"
                  :key="item.key"
                  :disabled="item.disable"
                  :show="item.show"
                  class="p-3"
                  @click.stop="handleActionItemClick(item, data.model)"
                >
                  {{ item.title }}
                </app-button-dropdown-item>
              </template>
            </app-button-dropdown>
          </div>
        </td>
      </template>
    </app-table>
  </div> 
</template>

<script>
import INVOICE_STATUS from "@/utils/const/invoice_status";

export default {
  data() {
    return {
      filters: {},
      showInvoicePdf: false,
      showInvoiceView: false,
      showDeleteConfirmation: false,
      selectedInvoice: null,
      action_items: [],
      invoiceStatus: INVOICE_STATUS,
      tabs: [],
      current_tab: null,
    };
  },

  mounted() {
    this.fetchListInvoices();

    this.tabs = [
      { key: "all", title: this.$t("invoice.table_header.tab_all") },
      { key: INVOICE_STATUS.PAID, title: this.$t("invoice.table_header.tab_paid") },
      { key: INVOICE_STATUS.OPEN, title: this.$t("invoice.table_header.tab_unpaid") },
      { key: INVOICE_STATUS.OVERDUE, title: this.$t("invoice.table_header.tab_overdue") },
    ];
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["invoiceStore/apiPaginationResponse"];
    },
    loading() {
      return this.$store.getters["invoiceStore/loading"];
    },
  },

  methods: {
    paramsChanged(filters, queryParams) {
      this.filters = filters;
      this.fetchListInvoices(this.filters);
    },
    async deleteInvoice() {
      const result = await this.$store.dispatch(
        "invoiceStore/deleteInvoice",
        this.selectedInvoice
      );
      if (!this.$lodash.isNil(result)) {
        this.refresh();
      }
      this.showDeleteConfirmation = false;
    },
    displayDeleteConfirmation(invoice) {
      this.selectedInvoice = invoice;
      this.showDeleteConfirmation = true;
    },
    goToCreateNewInvoice() {
      this.$router.push("/invoice/create");
    },
    fetchListInvoices(filters, queryParams) {
      this.getTabStatusTab();
      this.filters = filters;
      this.$store.dispatch("invoiceStore/retrieveInvoices", this.filters);
    },
    refresh() {
      this.fetchListInvoices();
    },
    async selectInvoice(data) {
      const result = await this.$store.dispatch(
        "invoiceStore/getInvoiceById",
        data._id
      );
      this.$store.dispatch(
        "customerStore/updateLocalCustomer",
        result.data.customer
      );
      this.$store.dispatch(
        "invoiceStore/getInvoiceHistory", 
        data._id
      );
      this.selectedInvoice = result.data;
    },
    async displayInvoicePdf(data) {
      await this.selectInvoice(data);
      this.showInvoicePdf = true;
    },
    async displayInvoiceView(data) {
      await this.selectInvoice(data);
      this.showInvoiceView = true;
    },
    displayEditInvoiceForm(data) {
      return this.$router.push(`/invoice/edit/${data._id}`);
    },
    getActionItems(status) {
      return [
        {
          key: "duplicate",
          title: this.$t("invoice.action.duplicate_invoice"),
          show:
            status !== INVOICE_STATUS.OVERDUE && status !== INVOICE_STATUS.VOID,
        },
        {
          key: "download",
          title: this.$t("invoice.action.download_as_pdf"),
          show: true,
        },
        {
          key: "delete",
          title: this.$t("invoice.action.delete_invoice"),
          show: status === INVOICE_STATUS.CREATED,
        },
      ];
    },
    handleActionItemClick(menu, data) {
      if (menu.key === "duplicate") {
        return this.$router.push(`/invoice/duplicate/${data._id}`);
      }

      if (menu.key === "download") {
        let payload = {
          businessId: data.business_id,
          invoiceId: data._id,
        };
        this.$store.dispatch("invoiceStore/downloadInvoicePdf", payload);
        return;
      }

      if (menu.key === "delete") {
        return this.displayDeleteConfirmation(data);
      }
    },
    tabChanged(tabName) {
      this.currentTab = tabName;
      this.$store.dispatch("invoiceStore/retrieveInvoices", this.filters);
    },
    getTabStatusTab() {
      var status_tab = null;
      let currentTab = this.current_tab ?? this.$route.query.tab;

      status_tab = currentTab;

      if (currentTab == "all") {
        status_tab = '';
      } 

      this.filters["filter[status_tab]"] = status_tab;
    },
  },
};
</script>